const state = {
  tab: 0,
  warehouse: [],
  date: {
    startDate: null,
    endDate: null,
  },
  shipmentNumber: null,
  driver: null,
  statusDelivery: null,
  selected: [],
};

const mutations = {
  setTab(state, val) {
    console.log("tab", val);
    state.tab = val;
  },
  setWarehouse(state, val) {
    state.warehouse = val;
  },
  setDate(state, val) {
    state.date = val;
  },
  setShipmentNumber(state, val) {
    state.shipmentNumber = val;
  },
  setDriver(state, val) {
    state.driver = val;
  },
  setStatusDelivery(state, val) {
    state.statusDelivery = val;
  },
  setSelected(state, val) {
    state.selected = val;
    console.log("selected", state.selected);
  },
  removeSelected(state, val) {
    //remove val from selected
    state.selected = state.selected.filter(
      (item) => item.delivery_order_id !== val.delivery_order_id
    );
  },
  addSelected(state, val) {
    state.selected.push(val);
    console.log("test item add", state.selected);
  },
};

const actions = {
  updateTab({ commit }, val) {
    commit("setTab", val);
  },
  updateWarehouse({ commit }, val) {
    console.log("warehouse", val);
    commit("setWarehouse", val);
  },
  updateDate({ commit }, val) {
    commit("setDate", val);
  },
  updateShipmentNumber({ commit }, val) {
    commit("setShipmentNumber", val);
  },
  updateDriver({ commit }, val) {
    commit("setDriver", val);
  },
  updateStatusDelivery({ commit }, val) {
    commit("setStatusDelivery", val);
  },
  updateSelected({ commit }, val) {
    commit("setSelected", val);
  },
};

const getters = {
  getWarehouseID(state) {
    return state.warehouse.map((item) => item.id);
  },
  getWarehouseName(state) {
    return state.warehouse.map((item) => item.name);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
